module services {
    export module logistics {
        export class vesselScheduleService implements interfaces.logistics.IVesselScheduleService {
            static $inject = ["$resource", "ENV","$http", "$timeout","generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            GetTerminalUpdate(terminalId: number): angular.resource.IResourceClass<interfaces.logistics.TerminalInfo> {
                  return this.$resource<interfaces.logistics.TerminalInfo>(this.ENV.DSP_URL + "Terminal/GetTerminalUpdate", {
                    terminalId: terminalId
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            GetTerminalsForDropDownByCountry(countryId: number, searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "VesselSchedule/GetTerminalsForDropDownByCountry", {
                    countryId: countryId,
                    searchText: searchText
                });
            }

            getList(): ng.resource.IResourceClass<interfaces.logistics.VesselScheduleDisplay> {
                return this.$resource<interfaces.logistics.VesselScheduleDisplay>(this.ENV.DSP_URL + "VesselSchedule/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            } 

            GetVesselSchedule(vesselScheduleId: number): ng.resource.IResourceClass<interfaces.logistics.VesselSchedule> {
                 return this.$resource<interfaces.logistics.VesselSchedule>(this.ENV.DSP_URL + "VesselSchedule/GetVesselSchedule", {
                    vesselScheduleId: vesselScheduleId
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            

            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "VesselSchedule/Save");
            }

            vesselLookup(): ng.resource.IResourceClass<interfaces.logistics.IVesselLookup> {
                return this.$resource<interfaces.logistics.IVesselLookup>(this.ENV.DSP_URL + "VesselSchedule/VesselLookup", {}, {
                    save: {
                        method: 'POST',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }});
            }

            getVesselScheduleListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'VesselSchedule/GetVesselScheduleExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'supplierDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }    

            uploadFromExcel(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "VesselSchedule/UploadFromExcel", {});
            }

            getExportVesselScheduleDashboardData(portTerminalId: number): ng.resource.IResourceClass<interfaces.logistics.ExportVesselScheduleDashboardView> {
                return this.$resource<interfaces.logistics.ExportVesselScheduleDashboardView>(this.ENV.DSP_URL + "VesselSchedule/GetExportVesselScheduleDashboardData", {
                    portTerminalId: portTerminalId
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            getGetPortTerminalsForDropDown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "VesselSchedule/GetGetPortTerminalsForDropDown", {
                });
            }      
            
            allocateConsignmentToVesselSchedule(conId:number, vesselSailingScheduleId:number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "VesselSchedule/AllocateConsignmentToVesselSchedule", {
                    conId: conId,
                    vesselSailingScheduleId: vesselSailingScheduleId

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }        
            
            getExportVesselScheduleDashboardExcel(portTerminalId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'VesselSchedule/GetExportVesselScheduleDashboardExcel?portTerminalId=' + portTerminalId ;
                    
                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getImportVesselScheduleDashboardExcel(portTerminalId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'VesselSchedule/GetImportVesselScheduleDashboardExcel?portTerminalId=' + portTerminalId ;
                    
                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }          
            
            getImportVesselScheduleDashboardData(portTerminalId: number): ng.resource.IResourceClass<interfaces.logistics.ImportVesselScheduleDashboardView> {
                return this.$resource<interfaces.logistics.ImportVesselScheduleDashboardView>(this.ENV.DSP_URL + "VesselSchedule/GetImportVesselScheduleDashboardData", {
                    portTerminalId: portTerminalId
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }            

        }
    }
    angular.module("app").service("vesselScheduleService", services.logistics.vesselScheduleService);
}